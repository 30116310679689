$foundation-palette: (
		primary: #95559C,
		secondary: #767676,
		success: #3adb76,
		warning: #ffae00,
		alert: #cc4b37,
);

$header-font-weight: bold;
$button-font-weight: bold;

$header-styles: (
		small: (
				'h1': ('font-size': 24),
				'h2': ('font-size': 20),
				'h3': ('font-size': 19),
				'h4': ('font-size': 18),
				'h5': ('font-size': 17),
				'h6': ('font-size': 16),
		),
		medium: (
				'h1': ('font-size': 48),
				'h2': ('font-size': 20),
				'h3': ('font-size': 31),
				'h4': ('font-size': 25),
				'h5': ('font-size': 20),
				'h6': ('font-size': 16),
		),
);
