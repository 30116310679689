.generating {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba( 255, 255, 255, 0.8 );
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	visibility: hidden;

	&:before {
		content: '';
		border: 5px solid lighten( $primary-color, 30%);
		height: 80px;
		width: 80px;
		border-radius: 50%;

		border-right-color: $primary-color;
		animation: spin 1s infinite;
	}

	&:after {
		content: 'Bezig met verwerking';
		margin-top: 10px;
		font-size: 24px;
		font-weight: bold;
		color: $primary-color;
	}

	&--active {
		visibility: visible;
	}
}


@keyframes spin {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}