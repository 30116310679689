.update-modal {
	position: fixed;
	bottom: 0;
	width: 100%;
	visibility: hidden;
	opacity: 0;
	transition: all .3s linear;

	&--visible {
		visibility: visible;
		opacity: 1;
	}

	&--inner {
		background: $primary-color;
		padding: $global-padding;
		color: #fff;
		border-radius: 10px;
		cursor: pointer;
	}
}