@import './settings/settings';
@import '~foundation-sites/scss/foundation';

@import './components/updateModal';
@import './components/generating';

textarea {
	border: 25px solid rgb(223, 231, 241) !important;
	margin-bottom: 0 !important;
}

.error {
	input, textarea {
		background: lighten( #ff0000, 40% )
	}
}

@include foundation-everything();